/* TODO: FIX SPACING TO FLOAT ATTRIBUTES */

.WorkRightContainer{
    margin: 0em 3em 0em 5em;
    
}

.WorkLeaf{
    width:200px;
    height: 200px;
    border-radius: 50% 0% 50% 50%;
    background-color: #145858;
    
    margin-bottom:-150px;
    margin-left: 150px;

}

.WorkCircle{
    height: 150px;
    width: 150px;
    background-color: #C8E0CA;
    border-radius: 50%;
    
    margin-left: 160px;
    margin-top: 90px;

    position:relative;
    z-index: 1;
}

.WorkLeafDesign{
    width:250px;
    height: 400px;
    background-color: #F7B318;
    border-radius: 55em 55em 75em 0em;
    margin-top:-180px;
   
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.WorkLeafInsideDesign{
    border: 5px solid #FCF7F4;
    width:200px;
    height: 350px;
    background-color: #F7B318;
    border-radius: 55em 55em 80em 0em;
    
}

/* Tablet */
@media (max-width: 820px) {

}

/* Phone */
@media (max-width: 414px) {
    .WorkRightContainer{
        Margin: 0;
    }

    .WorkLeaf{
        width: 150px;
        height: 150px;
        margin-left: 125px;
    }

    .WorkLeafDesign{
        width:200px;
        height: 300px;
        margin-top: -125px;
    }

    .WorkLeafInsideDesign{
        width: 150px;
        height: 250px;
    }

    .WorkCircle{
        height: 115px;
        width: 115px;
        margin-left: 135px;
    }

}

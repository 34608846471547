.WorkExperiencesCardsContainer{
    margin: 2em 0 2em 0;
}

.WorkExperienceCards{
    display:flex;
    flex-wrap: wrap;
    gap: 2em;
    align-items: flex-start;
    justify-content: center;
    margin: 0 3em;
}

/* Tablet */
@media (max-width: 820px) {
    .LandingContainer{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}

/* Phone */
@media (max-width: 414px) {
    .WorkExperienceCards{
        margin: 0;
        gap: 2em 0;
        
    }
}
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat&display=swap');


.WorkContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.WorkLandingContainer{
    display: flex;
    flex-direction: row;
    padding: 3em;
    justify-content: space-around;
    align-items: center;
}

.WorkExperienceContainer{
    width:100%;
    display: flex;
    flex-direction: column;
}

.WorkExperiencesTitle{
    font-family: Montserrat;
    color:#013237;
    margin: 50px 0px;
}

/* Tablet */
@media (max-width: 820px) {
    .WorkLandingContainer{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}

/* Phone */
@media (max-width: 414px) {
    .WorkLandingContainer{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 4em 0 0 0;
    }

    .WorkExperienceContainer{
        flex-direction: column;
    }

}
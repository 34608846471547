.carousel{
    margin: 2em 0 2em 0;
}

.carousel img{
    height: auto;
    max-width: 30em;
    min-width: 10em;
    border: solid 8px #F7B318;
    
}

.slide_direction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:20px;
}

.left{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 20px 10px 0;
    border-color: transparent #013237 transparent transparent;
}

.right{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #013237;
}
.indicator {
  display:flex;
  gap: 10px;
}

.dot {
  background-color: #013237;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.active {
  background-color: #F7B318;
}

.dot:hover{
  background-color: #C8E0CA;;
}

.right:hover{
  border-color: transparent transparent transparent #C8E0CA;
}

.left:hover{
  border-color: transparent #C8E0CA transparent transparent;
}

/* Tablet */
@media (max-width: 820px) {
}

/* Phone */
@media (max-width: 414px) {
  .carousel img{
     max-width: 20em; 
  }
}
.FooterContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width:80%;
    margin: 1em 0em 4em 0em;
}

.FooterLeft{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color:#013237;
}

.FooterLeft h2{
    margin: 0;
    font-family: Montserrat;
    font-weight: 900;
}

.FooterLeft h3{
    font-weight: 300;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.BottomLinkContainer{
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: space-around;
    align-items: center;
    height: 50px;
}

.SocialLink{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4em;
    width: 4em;
    border-radius: 50%;
}

.SocialLink img{
    height: 50px;
    width: 50px;
}

.SocialLink:hover{
    transition: 0.25s;
    border:solid 2px #C8E0CA;
}

.SocialLink:active{
    box-shadow: 0px 0px 20px 1px #C8E0CA;
    
}

.FooterRight{
    display: flex;
    flex-direction: column;
    color:#013237;
}

.FooterRight h3{
    font-size: 14px;
    font-weight: 500;
    font-family: Verrdana, Geneva, Tahoma, sans-serif;
    margin: 3px;
}

/* Tablet */
@media (max-width: 820px) {
    .FooterContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;
    }

    .FooterLeft{
        align-items: center;
    }

    .FooterLeft h2{
        font-size: 20px;
    }
    .FooterLeft h3{
        font-size: 14px;
    }
    .SocialLink{
        height: 3em;
        width: 3em;
    }

    .SocialLink img{
        width:40px;
        height: 40px;
    }

    .BottomLinkContainer{
        width: 300px;
    }

    .FooterRight h3{
        font-size: 12px;
    }
}

/* Phone */
@media (max-width: 414px) {
    .FooterContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat&display=swap');


.HomeContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LandingContainer{
    display: flex;
    flex-direction: row;
    padding: 3em;
    justify-content: space-around;
    align-items: center;
    gap: 5em;
}

/* Tablet */
@media (max-width: 820px) {
    .LandingContainer{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 3em 0;
        gap:2em;
    }
}

/* Phone */
@media (max-width: 414px) {
    .LandingContainer{
        padding: 4em 0em 4em 0em;
        
    }
}
/* TODO: FIX SPACING TO FLOAT ATTRIBUTES */

.AboutRightContainer{
    margin: 0em 3em 0em 5em;
    
}

.AboutLeaf{
    width:250px;
    height: 250px;
    border-radius: 0% 50% 50% 50%;
    background-color: #145858;
    
    margin-bottom:-150px;

}

.AboutCircle{
    height: 100px;
    width: 100px;
    border: 40px solid #C8E0CA;
    border-radius: 50%;
    
    margin-left: 300px;
    margin-top: 150px;

    position:relative;
    z-index: 1;
}

.AboutLeafDesign{
    width:300px;
    height: 400px;
    background-image: url("../AboutImages/Flower_Photo.jpg");
    background-position: -8em; 
    background-repeat: no-repeat; 
    background-size: 450px 600px; 
    border-radius: 55em 55em 0em 0em;
    margin-top:-350px;
    margin-left:100px;
   
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.AboutLeafInsideDesign{
    border: 5px solid white;
    width:250px;
    height: 350px;
    border-radius: 55em 55em 0em 0em;
    
}

/* Tablet */
@media (max-width: 820px) {

}

/* Phone */
@media (max-width: 414px) {
    .AboutRightContainer{
        margin: 0;
    }
    
    .AboutCircle{
        width:75px;
        height: 75px;
        margin-left: 235px;
        margin-top: 0px;
        border: 30px solid #C8E0CA;
    }

    .AboutLeaf{
        width: 150px;
        height: 150px;
        margin-bottom: 30px;
        margin-left:35px;
      
    }

    .AboutLeafDesign{
        width:200px;
        height: 300px;
        margin-top:-250px;
        margin-left:100px;
    }
    .AboutLeafInsideDesign{
        width: 150px;
        height: 250px;
        border: 4px solid white;
    }
}

/* Tablet */
@media (max-width: 820px) {

}

/* Phone */
@media (max-width: 414px) {
    .AboutLeafDesign{
        background-size: 300px 400px; 
        background-position: -5em; 
        background-repeat: no-repeat;
        
    }
}

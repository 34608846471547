.WorkLeftContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 30px 20px 30px;

    width: 80%;
    max-width: 400px;
    color: #013237;
}

.WorkLeftContainer h1{
    font-family: Montserrat;
    color:#013237;
}

.WorkLeftText{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.WorkLeftText h2{
    font-size: 20px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    display:flex;
    flex-wrap: wrap;
    line-height: 1.5em;
    justify-content: center;
    gap: 0 10px;
    font-weight: 500;
}

.WorkButton{
    background-color: #F7B318;
    padding: 30px 60px 30px 60px;
    border: none;
    border-radius: 1em;
    margin: 32px 0px 32px 0px;
    font-family: Montserrat;
    text-decoration: none;

    font-size: 20px;
    color: #013237;
}

.WorkButton:hover{
    background-color:#C8E0CA;
    color: #013237
}

.WorkButton:active{
        box-shadow: 0px 0px 20px 1px #C8E0CA;
}

.WorkLeftText a{
    text-decoration: none;
    font-weight: 800;
    color: #013237
}

.WorkLeftText a:hover{
    color:#F7B318;
    
}

/* Tablet */
@media (max-width: 820px) {
    .WorkLeftContainer h1{
        font-size: 30px;
    }
    .WorkLeftText h2{
        font-size: 16px;
    }
    .WorkButton{
        font-size: 16px;
    }

}

/* Phone */
@media (max-width: 414px) {

} 
.work-accordion-container{
    display:flex;
    justify-content: space-around;
    align-items: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 0 0 30px 0;
}

/* Tablet */
@media (max-width: 820px) {
    .work-accordion-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* Phone */
@media (max-width: 414px) {
    .work-accordion-container{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}
.AboutLeftContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 30px 20px 30px;

    width: 80%;
    max-width: 400px;
    color: #013237;
}

.AboutLeftContainer h1{
    font-family: Montserrat;
    color:#013237;
}

.AboutLeftText{
    display:flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

.AboutLeftText  h2{
    font-size: 20px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    display:flex;
    flex-wrap: wrap;
    line-height: 1.5em;
    justify-content: center;
    gap: 0 10px;
    font-weight: 500;
}

 /* Tablet */
@media (max-width: 820px) {
    .AboutLeftText{
       width: 300px;
    }
    .AboutLeftContainer h1{
        font-size: 30px;
    }
    .AboutLeftText h2{
        font-size: 16px;
    }
}


 /* Phone 
@media (max-width: 414px) {
    .{

    }
}  */
.accordion{
    justify-content: space-around;
}

.accordion-item{
    border: solid 3px #013237;
    margin:2em;
    width: 25em;
    padding: 2em;
    border-radius: 15px;

}

.accordion-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Montserrat',sans-serif;
    font-weight: 800;
    font-size: 18px;
    color:#013237;
    align-items: center
    
}

.accordiontitletext{
    text-align: left;
}

.accordion-content{
    margin-top: 2em;
    text-align: left;
}

/* Tablet */
@media (max-width: 820px) {
    .accordion-item{
        padding: 1.5em;
        margin: 1em 0;
        width:20em;
    }
}

/* Phone */
@media (max-width: 414px) {
    .accordion-item{
        width:16em;
    }
}
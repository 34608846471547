/* import image from "./../IMG_8003.JPG"; */

.LandingRightContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    min-width: 400px;
    height: 540px;
}

.LeafDesign{
    height: 300px;
    width: 200px;
    background-color: #145858;
    border-radius: 0% 55% 55% 55%;
    z-index: -1;
    position:absolute;
    top:5em;
    left:0;
}

.LeafImage{
    height: 400px;
    width: 300px;
    background-color: #555;
    border-radius: 50% 0% 50% 0%;
    z-index: -1;
    
    position: absolute;
    bottom:0;
    right:0;
    
    
    background-image: image;
    object-position: 50% 50%;
    
}

.LeafImage img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50% 0% 50% 0%;
}

.CircleText{
    width:200px;
    border-radius: 50%;
    z-index: -1;
    position:absolute;

    
   top:0;
   right:4em;
    
    -webkit-animation:spin 10s linear infinite;
    -moz-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


.CircleImage{
    height: 150px;
    width: 150px;
    background-color: #C8E0CA;
    border-radius: 50%;
    z-index: 1;
    position:absolute;
    
    left:0;
    bottom:3em;
    

    display:flex;
    justify-content: center;
    align-items: center;
}

.typewrite{
    height: 120px;
    width: 120px;
    background-color:#C8E0CA;
    border-radius: 50%;
    border: 5px solid white;

    font-size: 100px;

    display:flex;
    justify-content: center;
    align-items: center;

    font-size: 3em;
}

@media (max-width: 820px) {


}

/* Phone */
@media (max-width: 414px) {

    .LandingRightContainer{
        height:360px;
        min-width: 275px;
        flex-direction: column;
    }

    .LeafImage{
        height: 250px;
        width:200px;
    }
    .CircleText{
        width: 150px;
        right:2em;
    }

    .LeafDesign{
        height: 200px;
        width:150px;
    }

    .CircleImage{
        width: 100px;
        height: 100px;
        bottom: 1em;
        left:1em;
        
    }

.typewrite{
    height: 75px;
    width: 75px;
    font-size: 30px;
    border: 2px solid white;
}
}

.InfoCardItem a{
    text-decoration: none;
}

.InfoCardItem:hover .InfoCardTopText a {
    color: #F7B318;
}


.InfoCardContainer{
    width:20em;
    padding: 0 20px;
    border-radius: 5%;
    background: #C8E0CA;
    /* border: solid 2px #013237; */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    overflow: hidden;
}

.InfoCardContainer img{
    width: 120%;
    top:0; 
}

.InfoCardTop{
    position: relative;
    display: flex;
    justify-content: center;
}

.InfoCardTopText{
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    margin:20px;
}

.InfoCardTopText a{
    text-decoration: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color:#FCF7F4;
    font-weight: 700;
}

.InfoCardContainer h2{
    display: flex;
    justify-content: flex-end;
    margin: 0;
    color:#FCF7F4;
    font-family:Montserrat;
    z-index: 1;

}

.InfoCardBottom{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
    background: linear-gradient(0deg, rgba(200,224,202,1) 0%, rgba(200,224,202,0.5956976540616247) 85%, rgba(200,224,202,0) 100%);
    height: 100%;
    width: 100%;
}

.InfoCardBottomText{
    color: #013237;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 20px 0;
    gap: 3em;
}

.InfoCardBottomTopText{
    /* height: 5em; */
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.InfoCardBottom h1{
    font-family:Montserrat;
    text-align: left;
    margin:0;
}

.InfoCardBottom h3{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    text-align: left;
    margin:0;
    font-weight: 400;
}

.InfoCardBottom span{
    display: flex;
    width: 20em;
    font-size: 14px;
    font-weight: 900;
    font-family:Montserrat;
    text-align: left;
}

/* Tablet */
@media (max-width: 820px) {
    .InfoCardContainer img{
        width: 25em;
    }
    .InfoCardTopText{
        width: 80%;
    }
}

/* Phone */
@media (max-width: 414px) {
    .InfoCardContainer{
        width: 18em;
    }
    .InfoCardTopText{
        width: 75%;
    }

}
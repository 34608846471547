.linkscontainer{
    background-color: #FCF7F4;
}

.skip-to-content-links{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  padding: 3em;
  width: 75%;
  background-color: #FCF7F4;
  position: absolute;

  transform: translateY(-120%);
  transition: transform 0.5s;
}

.skip-to-content-links:focus-within{
  transform: translateY(0%)
}


.NavBarContainer{
    display:flex;
    flex-direction: row;
    width:80vw;
    align-items: center;
    justify-content: space-between;

    font-family: 'Montserrat',sans-serif;

    margin-top: 3em;
}

.NavBarLeft{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 300px;
}

.logo{
    height: 50px;
    width: 50px;
}

.NavBarRight{
    display: flex;
    float: right;
    flex-wrap: wrap;
    margin: 20px 5px;
    padding:5px 25px;
    background-color: transparent;
}

.hamburger{
  display:none;
}

.toplinkscontainer{
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
  background-color: transparent
} 

.NavBarContainer a{
  display:flex;
  align-items: center; 
  gap: 10px; 
  font-weight: 700;
  text-decoration:none;
  color: #145858;
  padding: 10px;
  border-radius: 5px;
}

.NavBarContainer a:hover{
  transition: 0.25s;
  border: solid 2px #C8E0CA;
}

.NavBarContainer a:active{
  box-shadow: 0px 0px 20px 1px #C8E0CA;
  border-color: #C8E0CA;
}

.toplinkscontainer a{
  padding: 10px 10px;
  display:flex;
  align-items: center; 
  gap: 10px; 
  font-weight: 700;
  text-decoration:none;
  color: #145858;
  border-radius: 5px;
}

.toplinkscontainer a:hover{
  border-color: #C8E0CA;
}

.toplinkscontainer a:active{
    box-shadow: 0px 0px 20px 1px #C8E0CA;
    border-color: #C8E0CA;
  }

.NavBarTitle{
  font-size: 16px;
}

@media (max-width: 820px) {
  .NavBarRight{
    justify-content: flex-end;
    margin:0;
    padding:0;
    display:flex;
    flex-wrap:nowrap;
  }  
  
  .hamburger{
    display: block;
    z-index:10;
  }

  .toplinkscontainer{
    position:absolute;
    width: 100px;
    margin-top: 40px;
    padding: 10px;
  }

  .toplinkscontainer a{
    padding: 10px 10px;
    display:flex;
    flex-direction: column;
    align-items:flex-end;
    
}
}

/* Phone  */
 @media (max-width: 414px) {
    .NavBarTitle{
      display: none;
    }

    .NavBarLeft{
      justify-content: flex-start;
    }
}